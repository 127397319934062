<template>
  <div>
    <NavBarAdmin></NavBarAdmin>
    <div style="overflow-y: auto; height: 700px;">
      <div class="container text-center" style="margin-left: 13%">
        <br />
        <h3 class="h4" style="color: #a19c9c">Ganancias de {{ meses[mes] }}</h3>
        <table class="table table-hover">
          <thead>
            <tr style="background-color: #a19c9c">
              <th class="h5 text-white">Marco de Tiempo</th>
              <th class="h5 text-white">Cantidad</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="h6">Ventas Diarias</th>
              <td class="h6">${{ getSales(todaySales) }}</td>
            </tr>
            <tr>
              <th class="h6">Ventas Semanal</th>
              <td class="h6">${{ getSales(weekSales) }}</td>
            </tr>
            <tr>
              <th class="h6">Ventas Mensual</th>
              <td class="h6">${{ getSales(monthSales) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container text-center mt-6" style="margin-left: 13%;">
        <br />
        <h3 class="h4" style="color: #a19c9c">
          Reporte de Ganancias General Anual
        </h3>
        <table class="table table-hover">
          <thead>
            <tr style="background-color: #a19c9c">
              <th class="h5 text-white">Mes</th>
              <th class="h5 text-white">Ganancia</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="h6">{{ meses[0] }}</th>
              <td class="h6">${{ getSales(earns[0]) }}</td>
            </tr>
            <tr v-for="i of mes" :key="i">
              <th class="h6">{{ meses[i] }}</th>
              <td class="h6">${{ getSales(earns[i]) }}</td>
            </tr>

            <tr>
              <th class="h5" style="font-weight: bold">Total Anual Actual</th>
              <td class="h5" style="font-weight: bold">
                ${{ getSales(allSales) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <div class="my-2">
          <v-btn small color="secondary" dark @click="getProductsReport()">
            Obtener Reporte de Productos
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBarAdmin from "../../components/NavBarAdmin.vue";
import Parse from "parse";
import xlsx from "xlsx";
export default {
  name: "ReportsAdmin",
  components: {
    NavBarAdmin
  },
  data() {
    return {
      allSales: null,
      todaySales: null,
      monthSales: null,
      weekSales: null,
      storeId: null,
      superAdmin: false,
      admin: false,
      fecha: null,
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      mes: 0,
      earns: [],
      totalEarns: 0
    };
  },
  mounted() {
    this.fecha = new Date();
    this.superAdmin = Parse.User.current().get("superAdmin");
    this.admin = Parse.User.current().get("isAdmin");
    if (this.admin && !this.superAdmin) {
      this.$router.push("/userTabs");
    }
    if (!this.admin && !this.superAdmin) {
      this.$router.push("/");
    }
    let meses = new Date();
    this.mes = meses.getMonth();
    this.getTodaySales();
    this.getTotalSales();
    this.getMonthSales();
    for (let a = 0; a <= this.mes; a++) {
      this.getSalesByMonth(a);
    }
  },
  methods: {
    async getProductsReport() {
      Parse.Cloud.run("getReportProducts")
        .then(result => {
          const ws = xlsx.utils.json_to_sheet(result);
          const wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
          xlsx.writeFile(wb, "Reporte productos activos Farmacia.xlsx");
        })
        .catch(err => {
          this.$swal({
            title: "Ah ocurrido un error",
            icon: "error",
            html: err
          });
        });
    },
    getSales(sales) {
      let total = 0.0;
      if (sales) {
        total = parseFloat(sales).toFixed(2);
      }
      return total;
    },
    firstDayWeek(d) {
      let day = d.getDay();
      let diff = 0 - day;
      return new Date(d.setDate(d.getDate() + diff));
    },
    lastDayWeek(d) {
      let day = d.getDay();
      let diff = 0 - day + 6;
      return new Date(d.setDate(d.getDate() + diff));
    },
    async getWeekSales() {
      // this.monthSales = await Parse.Cloud.run("getMonthlyEarnigs");
      let startDate = this.firstDayWeek(this.fecha); //this.fecha;
      let endDate = this.lastDayWeek(this.fecha);
      let query = new Parse.Query("ServiceRequest");
      query.count().then(total => {
        query.limit(total);
        query.equalTo("status", "F");
        query.lessThan("createdAt", endDate);
        query.greaterThan("createdAt", startDate);
        query.find().then(orders => {
          for (let order of orders) {
            if (order.get("storeGain")) {
              this.weekSales += order.get("storeGain");
            }
          }
        });
      });
    },
    async getTodaySales() {
      let endDate = this.fecha;
      let startDate = this.fecha;
      let query = new Parse.Query("ServiceRequest");
      query.count().then(total => {
        query.limit(total);
        query.equalTo("status", "F");
        query.lessThan("createdAt", endDate);
        query.greaterThan("createdAt", startDate);
        query.find().then(orders => {
          for (let order of orders) {
            if (order.get("storeGain")) {
              this.todaySales += order.get("storeGain");
            }
          }
        });
      });
    },
    async getTotalSales() {
      let startDate = new Date(this.fecha.getFullYear(), 1, 1); //this.fecha;
      let endDate = this.fecha;
      let query = new Parse.Query("ServiceRequest");
      query.count().then(total => {
        query.limit(total);
        query.equalTo("status", "F");
        query.lessThan("createdAt", endDate);
        query.greaterThan("createdAt", startDate);
        query.find().then(orders => {
          for (let order of orders) {
            if (order.get("storeGain")) {
              this.allSales += order.get("storeGain");
            }
          }
        });
      });
    },
    async getMonthSales() {
      // this.monthSales = await Parse.Cloud.run("getMonthlyEarnigs");
      let startDate = new Date(
        this.fecha.getFullYear(),
        this.fecha.getMonth(),
        1
      ); //this.fecha;
      let endDate = this.fecha;
      let query = new Parse.Query("ServiceRequest");
      query.count().then(total => {
        query.limit(total);
        query.equalTo("status", "F");
        query.lessThan("createdAt", endDate);
        query.greaterThan("createdAt", startDate);
        query.find().then(orders => {
          for (let order of orders) {
            if (order.get("storeGain")) {
              this.monthSales += order.get("storeGain");
            }
          }
        });
      });
    },
    async getSalesByMonth(mes) {
      let sumatoria = 0;
      let startDate = new Date(this.fecha.getFullYear(), mes, 1); //this.fecha;
      let endDate = new Date(this.fecha.getFullYear(), mes + 1, 1);
      let query = new Parse.Query("ServiceRequest");
      query.count().then(total => {
        query.limit(total);
        query.equalTo("status", "F");
        query.lessThan("createdAt", endDate);
        query.greaterThan("createdAt", startDate);
        query.find().then(orders => {
          if (orders.length !== 0) {
            for (let order of orders) {
              if (order.get("storeGain")) {
                sumatoria += order.get("storeGain");
              }
            }
          } else {
            sumatoria = 0;
          }
          this.earns[mes] = sumatoria;
        });
      });
    }
  }
};
</script>
<style scoped>
.navbar,
.navbar-expand-lg,
.navbar-light,
.bg-light {
  background: #e0e0e0 !important;
}

.nav-item {
  font-family: "Montserrat", sans-serif;
}

.nav-link {
  color: black !important;
  font-weight: 200;
  font-size: 13px;
  /* letter-spacing: 1px; */
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.5s;
}

.nav-link:hover {
  color: #0e9390 !important;
  margin-left: 20px;
  margin-right: 20px;
}

.logo-link {
  position: absolute;
  left: 20px;
  top: 10px;
}

.logo {
  width: 70px;
  height: 50px;
  object-fit: contain;
  margin-right: 30px;
}

.navbar-toggler {
  border: 2px solid rgb(184, 184, 184) !important;
}

.fa-bars {
  color: rgb(184, 184, 184);
  font-size: 25px;
}

.icon3 {
  color: #029693;
  margin-right: 10px;
}

.card-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: center;
  color: white;
}

.card-text {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: white;
}

.card {
  background: #0e9390 !important;
  border: 0;
  box-shadow: 0 8px 6px -6px black;
}
</style>
